

.card {
	max-width: 32%;
	min-width: 32%;
	margin-bottom: 1em;
	background: black;
}

.sidebar-deal-with{
	margin-left: 15%;
	margin-right: 5% ;
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
}

.card-img{
	width: 100%;
	height: 300px;
	object-fit: contain;
	border: solid black 5px;
	box-sizing: border-box;
	/*border-style: solid;*/
}



.name {
	background: black;
	color: white;
	text-align: center;
	width: 100%;
	display: block;
	padding-bottom: 15px;
	text-decoration: none;
	font-size: 20px;
	font-family: Inconsolata, serif;
}

@media only screen and (max-width: 768px) {
	.card-img{
	width: 100%;
	height: 80px;
	object-fit: contain;
	border: solid black 5px;
	box-sizing: border-box;
	/*border-style: solid;*/

}
.name {
	
	font-size: 12px;
}
.sidebar-deal-with {
	margin-left: 23vw;
}
}
