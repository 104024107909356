.collapsible-entire{
    padding-left:20%;
    padding-right:90px;
}

@media only screen and (max-width: 768px) {
    .collapsible-entire{
        padding-left:23vw;
        padding-right:30px;
    }
}

.collapsible-header{
    cursor: pointer;
    padding: 5px;
    background-color: #FFF;
    color: black;
    font-family: Libre Baskerville;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.collapsible-content{
    cursor: pointer;
    /* border-bottom: solid 1px #f2f2f2;
    border-radius: 0 0 5px 5px; */
    background-color: #FFF;
    /* padding: 15px; */
    /* font-family: Inconsolata;
    font-style: normal;
    font-size: 14px; */
    color: black;
    font-family: Libre Baskerville;
    font-weight: bold;
    font-size: 16px;
    /* position: relative; */
    /* margin-left: 100px;
    margin-right: 100px; */
    /* font-weight: lighter; */
    text-align: left;
}

/* .collapsible-position{
    transform: translate(10px, 10px)
} */

h1{
    color: #84A1B6;
    text-align: center;
    font-size: 40px;
}


h2{
    font-size: 16px;
    text-align: left;
}

.tab { margin-left: 50px; }

.bullets{
    text-align: left;
}