.landing-image{
	width: 100vw;
	height: 80vh;
	left: 0%;
	top: 0%;
	z-index: 1;
	-webkit-transition: opacity 0.7s ease-in-out;
	-moz-transition: opacity 0.7s ease-in-out;
	-o-transition: opacity 0.7s ease-in-out;
	transition: opacity 0.7s ease-in-out;
	position: fixed;
}

.landing-image-static {
	width: 100vw;
	height: 40vh;
}

.landing {
	width: 100vw;
	height: 100vh;
	position: relative;
	z-index: 2;
	background-color: grey;
}



.year {
	font-size: 250px;
	font-weight: bold;
	font-family: 'Libre Baskerville', serif;
	color: white;
	border-bottom: 20px solid white;
	line-height: 0.6;
	text-align: right;
	position: absolute;
	bottom: 30vh;
	right: 0px;
	width: 100%;
	z-index: 3;
	padding-right: 20px;
}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.year {
	  font-size:120px;
	  font-weight: bold;
	font-family: 'Libre Baskerville', serif;
	color: white;
	border-bottom: 20px solid white;
	line-height: 0.6;
	text-align: right;
	position: absolute;
	bottom: 30vh;
	left: 0%;
	width: 100%;
	z-index: 3;
	padding-right: 20px;
	}
  }

.title {
	position: absolute;
	font-size: 50px;
	bottom: 45%;
	right: 0px;
	color: black;
	font-style: italic;
	text-transform: uppercase;
	z-index: 4;
}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.title {
	  font-size:20px;
	  position: absolute;
	bottom: 45%;
	right: 8%;
	color: black;
	font-style: italic;
	text-transform: uppercase;
	z-index: 4;
	}
  }

.author {
	position: absolute;
	bottom: 22vh;
	font-family: 'Roboto', serif;
	font-size: 20px;
	right: 10%;
	color: white;
	letter-spacing: 9px;
	text-transform: uppercase;
	z-index: 4;
}

.ad {
	height: 20vh;
	background: black;
	bottom: 0px;
	position: absolute;
	width: 100%;
}

