#draggable { width: 150px; height: 150px; padding: 0.5em; }

.backgroundimage {
    max-width: 90%;
    height: auto;
}

button {
    color: #fff !important;
    text-decoration: none;
    background: #84A1B6;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    }
button:hover {
        background: #434343;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
}

.face {
        border-radius:50%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        width: 12vh;
        height: 10vx;
        overflow: hidden;
 }

.galleryimage {
    max-width: 15%;
    margin: 10px;
    align-self: center;
    height: auto
}